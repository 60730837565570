<template>
  <b-container class="overflow-auto" fluid>
    <a @click="goBack" style="border: 1px black"><span class="fa fa-long-arrow-left"></span> Back</a>
    <!-- SM screen -->
    <h5 class="my-3 d-block d-sm-none" style="max-width: 450px; min-width: 140px; word-wrap: break-word;">Showing ads for campaign: <strong class="" >{{ typeof(ad.list[0])==='undefined' ? '': ad.list[0].CampaignName }}</strong> </h5>
    <!-- * EXCEPT SM screen -->
    <h5 class="my-3 d-none d-sm-block" >Showing ads for campaign: <strong class="">{{ typeof(ad.list[0])==='undefined' ? '': ad.list[0].CampaignName }}</strong> </h5>

    <!-- Ad Interface controls -->
    <a-row>
      <a-col :xl="3" :md="6" :xs="12">
        <a-checkbox v-model="showActive">
          Show only active
        </a-checkbox>
      </a-col>
    </a-row>
    <b-row>
      <b-col class="my-1" md="6">
        <b-form-group class="mb-0" label="Per page:" label-cols-sm="2">
          <perPageSelect v-model="perPage" :storageKey="'xml-ads'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <columnSelect :storageKey="'xml-ads-columns'" :options="fields"></columnSelect>
        </b-form-group>
      </b-col>
      <b-col class="my-1 w-max d-md-flex d-lg-flex flex-row-reverse" style="@media (max-width: 500px) {
        .flex-start {
        display: flex;
        justify-content: start;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 50%;
    }
      }" md="6">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      :bordered="true"
      :current-page="currentPage"
      :fields="filteredFields"
      :filter="filter"
      :hover="true"
      :items="showAds"
      :per-page="perPage"
      :small="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :striped="true"
      :tbody-tr-class="rowClass"
      show-empty
      stacked="md"
      @filtered="onFiltered"
    >
      <template #cell(DefaultCPC)="data">
        {{ Math.round((data.item.DefaultCPC + Number.EPSILON) * 10000) / 10000 }}
      </template>
      <template #cell(Pubfeeds)="data">
        <div class="items-center">
          {{ data.item.Pubfeeds }}
        </div>
      </template>
      <template #cell(Id)="data">
        <div class="items-center">
          {{ data.item.Id }}
        </div>
      </template>
      <template #cell(Name)="data">
        <div class="items-center">
          {{ data.item.Name }}
        </div>
      </template>
      <template #cell(CampaignName)="data">
        <!-- SM screen -->
        <div class="d-block d-sm-none">
          <!-- name slot -->
          <div class="items-center">
            <div v-if="data.item.CampaignName.length<=20">{{ data.item.CampaignName }}</div>
            <div class="text-left" style="max-width: 200px; min-width: 140px; word-wrap: break-word;" v-if="data.item.CampaignName.length>20">{{ data.item.CampaignName }}</div>
          </div>
        </div>
        <!-- * EXCEPT SM screen -->
        <div class="d-none d-sm-block">
          <!-- name slot -->
          <div class="items-center">
            <div data-toggle="tooltip" data-placement="top" :title="data.item.CampaignName" class="" v-if="data.item.CampaignName.length<20">{{ data.item.CampaignName }}</div>
            <div data-toggle="tooltip" data-placement="top" :title="data.item.CampaignName" class="" v-if="data.item.CampaignName.length>=20">{{ data.item.CampaignName.substring(0,20)+".." }}</div>
          </div>
        </div>
      </template>
      <template #cell(StatusString)="data">
        <a-checkbox :checked="(data.item.Status == 0)" @change="toggleAdStatus(data.item)">
        </a-checkbox>
      </template>
      <template #cell(actions)="data">
        <router-link class="add-button" @click.stop :to="{ name: 'xml-ad-update', params: { id: data.item.Id }  }">
          <b-button
            class="btn-info mr-1"
            size="sm"
            @click="editAd(data.item.Id)"
          ><i class="fa fa-edit"></i></b-button>
        </router-link>
        <b-button
          class="btn-info mr-1"
          size="sm"
          @click="copyAd(data.item.Id , data.item)"
        ><i class="fa fa-copy"></i></b-button>
      </template>
    </b-table>
<!-- CampaignName -->
    <b-row>
      <b-col class="my-1" md="6">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
            (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) + perPage).toLocaleString()
          }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <button v-if="campaignStatus===0" class="btn btn-success px-5" @click="redirectToCreateAd()">Create Ad</button>
        <b-alert show variant="danger" v-else> Campaign is Deactivated </b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import columnSelect from '@/components/custom/tableColumnSelect'
import perPageSelect from '@/components/custom/PerPageSelect'
import { Modal } from 'ant-design-vue'
import { cloneAd } from '@/api/xml/ad'
import { getCampaigns } from '@/api/xml/campaign'

export default {
  props: ['campaign'],
  components: {
    columnSelect,
    perPageSelect,
  },
  data() {
    return {
      campaignStatus: 0,
      days: [
        {
          title: 'Saturday',
          shortcut: 'sat',
        },
        {
          title: 'Sunday',
          shortcut: 'sun',
        },
        {
          title: 'Monday',
          shortcut: 'mon',
        },
      ],
      selectedDays: ['sun'],
      fields: [
        {
          key: 'actions',
          label: 'Actions',
          show: true,
          class: 'text-center',
          thStyle: 'width:90px',
        },
        {
          key: 'Id',
          label: 'Ad ID',
          show: true,
          headerTitle: 'Ad ID',
          sortable: true,
          thStyle: 'width:70px',
          tdClass: 'text-right',
        },
        {
          key: 'Name',
          label: 'Ad Name',
          show: true,
          headerTitle: 'Ad Name',
          sortable: true,
        },
        {
          key: 'CampaignName',
          label: 'Campaign',
          show: true,
          headerTitle: 'Campaign Name',
          sortable: true,
          thStyle: 'width:180px',
        },
        {
          key: 'Pubfeeds',
          label: 'Feeds',
          show: true,
          headerTitle: 'Feeds',
          sortable: false,
        },
        {
          key: 'DefaultCPC',
          label: 'Default CPC',
          headerTitle: 'Default Cost per Click',
          show: true,
          sortable: true,
          class: 'text-center',
          thStyle: 'width:100px',
        },
        {
          key: 'ClicksDailyLimit',
          label: 'Clicks Daily Limit',
          show: true,
          headerTitle: 'Clicks Daily Limit',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:140px',
          summed: false,
        },
        {
          key: 'Title',
          label: 'Title',
          show: false,
          sortable: true,
        },
        {
          key: 'Description',
          label: 'Description',
          show: false,
          sortable: true,
        },
        {
          key: 'Description2',
          label: 'Description2',
          headerTitle: 'Description2',
          show: false,
          sortable: true,
        },
        {
          key: 'AdDomainBrand',
          label: 'Ad Domain/Brand',
          headerTitle: 'Ad Domain/Brand',
          show: false,
          sortable: true,
        },
        {
          key: 'DestinationUrl',
          label: 'Destination URL',
          show: false,
          sortable: true,
        },
        {
          key: 'StatusString',
          label: 'Active',
          show: true,
          class: 'text-center',
          thStyle: 'width:80px',
        },
      ],
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'desc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    }
  },
  computed: {
    ...mapState(['ad', 'settings']),
    ...mapGetters('ad', ['getAds', 'getActiveAds']),
    showActive: {
      get: function () {
        return this.settings.showActiveCampaignAds
      },
      set: function (value) {
        this.$store.commit('CHANGE_SETTING', { setting: 'showActiveCampaignAds', value })
      },
    },
    showAds() {
      return this.showActive ? this.getActiveAds : this.getAds
    },
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    totalRows() {
      return this.showAds.length
    },
  },
  methods: {
    redirectToCreateAd() {
      this.$router.push({ name: 'xml-ad-create-id', params: { campaignId: this.$route.params.id } }).catch(() => {})
    },
    goBack() {
      this.$router.go(-1)
    },
    getAd(id) {
      cloneAd(id).then(response => {
        if (response) {
          // this.$router.push('/xml/ad/update/' + response.Ad.Id)
          this.$router.push({ name: 'xml-ad-update', params: { id: response.Ad.Id } }).catch(() => {})
          this.$notification.success({
            message: 'Ad cloned',
            description: `Ad: ${response.Ad.Name} has been successfully cloned.`,
          })
        }
      }).catch(error => console.log(error))
    },
    copyAd(id, ad) {
      Modal.confirm({
        title: 'Are you sure you want to clone ad?',
        content: h => <div>{status} Clone ad: <strong>{ad.Name}</strong>?</div>,
        onOk: function () {
          this.getAd(id)
        }.bind(this),
      })
    },
    rowClass(item, type) {
      if (item != null && item.Status === 1) return 'text-muted'
    },
    editAd(id) {
      // this.$router.push('/xml/ad/update/' + id)
      this.$router.push({ name: 'xml-ad-update', params: { id: id } }).catch(() => {})
    },
    toggleAdStatus(ad) {
      const status = ad.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change ad status?',
        content: h => <div>{status} ad <strong>{ad.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = ad.Status === 0 ? 1 : 0
          this.$store.dispatch('ad/CHANGE_STATUS', {
            id: ad.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  created() {
    this.$store.dispatch('ad/LOAD_ADS_FOR_CAMPAIGN', { CampaignId: this.$route.params.id })
    console.log(this.$route.params.id)
    getCampaigns(this.$route.params.id).then(response => {
      if (response) {
        this.campaignStatus = response.Status
      }
    })
  },
  mounted() {
  },
}
</script>

<style>

</style>
